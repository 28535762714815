<template>
  <q-page padding class="text-center column justify-center">
    <m-guest-blocker />
  </q-page>
</template>

<script>
import { MGuestBlocker } from 'components/'

export default {
  components: { MGuestBlocker }
}
</script>
